import { RoomTheme } from "../recommendation/types";

export const CHALLENGE_TYPES = ["program", "goal"] as const;
type ChallengeType = (typeof CHALLENGE_TYPES)[number];

export type Challenge = {
  _id: string;
  inserted: number;
  updated: number;

  name: string;
  internalName: string;
  lengthDescription: string;
  description: string;
  typeLabel: string;
  participants: string;
  primaryColor: string;
  secondaryColor: string;
  heroImage: string;
  badgeImage: string;
  milestoneBadge: string;
  allowMultipleEnrollment: boolean;
  fixActivityDates: boolean;

  type?: ChallengeType;
  programs: string[];
  goalIds?: string[];

  agnosticStartDate: Date;
  agnosticEndDate: Date;

  publishDate: Date;
  unpublishDate: Date;

  sortOrder: number;
  roomTheme?: RoomTheme;
  categories?: Partial<Record<string, true | string[]>>;
  enrolTitle?: string;
  enrolMessage?: string;
  enrolImageUrl?: string;
  challengeEndedTitle?: string;
  challengeEndedMessage?: string;
  challengeEndedImageUrl?: string;
};

export type ChallengeResponse = Omit<
  Challenge,
  "agnosticStartDate" | "agnosticEndDate" | "publishDate" | "unpublishDate"
> & {
  agnosticStartDate: string;
  agnosticEndDate: string;
  publishDate: string;
  unpublishDate: string;
};
