import React from "react";

function SvgComponent(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.26884 2.99217C9.45176 2.50219 10.7196 2.25 12 2.25C13.2804 2.25 14.5482 2.50219 15.7312 2.99217C16.9141 3.48216 17.9889 4.20034 18.8943 5.10571C19.7997 6.01108 20.5178 7.08591 21.0078 8.26884C21.4978 9.45176 21.75 10.7196 21.75 12C21.75 13.2804 21.4978 14.5482 21.0078 15.7312C20.5178 16.9141 19.7997 17.9889 18.8943 18.8943C17.9889 19.7997 16.9141 20.5178 15.7312 21.0078C14.5482 21.4978 13.2804 21.75 12 21.75C10.7196 21.75 9.45176 21.4978 8.26884 21.0078C7.08591 20.5178 6.01108 19.7997 5.10571 18.8943C4.20034 17.9889 3.48216 16.9141 2.99217 15.7312C2.50219 14.5482 2.25 13.2804 2.25 12C2.25 10.7196 2.50219 9.45176 2.99217 8.26884C3.48216 7.08591 4.20034 6.01108 5.10571 5.10571C6.01108 4.20034 7.08591 3.48216 8.26884 2.99217ZM12 3.75C10.9166 3.75 9.8438 3.96339 8.84286 4.37799C7.84193 4.7926 6.93245 5.40029 6.16637 6.16637C5.40029 6.93245 4.79259 7.84193 4.37799 8.84286C3.96339 9.8438 3.75 10.9166 3.75 12C3.75 13.0834 3.96339 14.1562 4.37799 15.1571C4.79259 16.1581 5.40029 17.0675 6.16637 17.8336C6.93245 18.5997 7.84193 19.2074 8.84286 19.622C9.8438 20.0366 10.9166 20.25 12 20.25C13.0834 20.25 14.1562 20.0366 15.1571 19.622C16.1581 19.2074 17.0675 18.5997 17.8336 17.8336C18.5997 17.0675 19.2074 16.1581 19.622 15.1571C20.0366 14.1562 20.25 13.0834 20.25 12C20.25 10.9166 20.0366 9.8438 19.622 8.84286C19.2074 7.84193 18.5997 6.93245 17.8336 6.16637C17.0675 5.40029 16.1581 4.7926 15.1571 4.37799C14.1562 3.96339 13.0834 3.75 12 3.75Z"
        fill="#212120"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11.25C12.4142 11.25 12.75 11.5858 12.75 12V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V12C11.25 11.5858 11.5858 11.25 12 11.25Z"
        fill="#212120"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.24C12.4142 8.24 12.75 8.57579 12.75 8.99V9C12.75 9.41421 12.4142 9.75 12 9.75C11.5858 9.75 11.25 9.41421 11.25 9V8.99C11.25 8.57579 11.5858 8.24 12 8.24Z"
        fill="#212120"
      />
    </svg>
  );
}

export default SvgComponent;
