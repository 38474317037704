import { omit } from "lodash";
import { SendRequest } from "../../api/makeSendRequest";
import { z } from "zod";

export const GOAL_TYPES = ["distance", "completion"] as const;
export const GOAL_TYPE_SCHEMA = z.enum(GOAL_TYPES);
export type GoalType = z.infer<typeof GOAL_TYPE_SCHEMA>;

export type Goal = {
  _id: string;
  inserted: number;
  updated: number;
  publishDate: number;
  sortOrder: number;

  name: string;
  internalName: string;
  description: string;
  onDemand: boolean;
  imageUrl: string;
  onlyShowInChallenge: boolean;

  type: GoalType;
  distanceM?: number;
  count?: number;

  badge?: { lockedUrl: string; unlockedUrl: string };
};

export type GoalSortOrderUpdatePayload = {
  goalId: string;
  sortOrder: number;
}[];

export type GoalCreateOrUpdatePayload = Omit<Partial<Goal>, "badge">;

export const createGoalApi = (sendRequest: SendRequest) => ({
  goalList: async () =>
    sendRequest<Goal[]>({
      method: "post",
      path: "goal/goal-list",
      version: "v3",
    }),
  goalSortOrderUpdate: (payload: GoalSortOrderUpdatePayload) =>
    sendRequest({
      method: "post",
      path: "goal/goal-sort-order-update",
      version: "v3",
      data: payload,
    }),
  goalCreateOrUpdate: async (payload: GoalCreateOrUpdatePayload) => {
    try {
      return await sendRequest<{ goalId: string }>({
        method: "post",
        path: "goal/goal-create-or-update",
        version: "v3",
        data: omit(payload, ["inserted", "updated"]),
      });
    } catch (e) {
      if (e?.response?.data?.error?.message)
        throw new Error(e.response.data.error.message);
      throw e;
    }
  },
  goalView: (goalId: string) =>
    sendRequest<Goal>({
      method: "post",
      path: "goal/goal-view",
      version: "v3",
      data: { goalId },
    }),

  goalDelete: (goalId: string) =>
    sendRequest<{ success: true }>({
      method: "post",
      path: "goal/goal-delete",
      version: "v3",
      data: { goalId },
    }),
});
