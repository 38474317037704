import { RoomTheme } from "./recommendation/types";

export * from "./recipes/types";
export * from "./subscriptionPlans/types";
export * from "./tags/types";
export * from "./users/userApiTypes";

export type PostCategory = {
  name: WorkoutType;
  title: string;
  subcategories?: {
    name: string;
    title: string;
  }[];
};

export type WorkoutType =
  | "hiit"
  | "boxing"
  | "pilates"
  | "meditation"
  | "yoga"
  | "strength"
  | "educational"
  | "warmup"
  | "pregnancy"
  | "cooldown"
  | "low-impact";

export const PERINATAL_STATUSES = [
  "prenatal",
  "postnatal",
  "perinatal",
  "pregnancyFriendly",
] as const;

export type PerinatalStatus = (typeof PERINATAL_STATUSES)[number];

export type Masterclass = Post<{
  audio?: string;
  description: string;
  difficulty?: "intermediate" | "intro";
  level?: "beginner" | "intermediate" | "advanced";
  duration_minutes: number;
  equipment: string[];
  trainer: any;
  video: string;
  categories: Partial<Record<WorkoutType, true | string[]>>;
}>;

export type ApiPaginatedResponse<T> = {
  data: T;
  settings: {
    limit: number;
    next: number;
    page: number;
    pages: number;
    total: number;
  };
};

export type ApiStage = "development" | "staging" | "production";
export type DatabaseStage = "development" | "production";

export type PostType = "masterclass" | "breakdown" | "recipe";

export type Post<T extends Record<string, unknown>> = {
  id: string;
  _id?: string;
  favourited: boolean;
  liked: boolean;
  post_type: PostType;
  snippet: string;
  status: "published" | "draft" | "pending_review";
  visibility: "public" | "private";
  subtitle: string;
  thumbnail?: string;
  title: string;
  internalName: string;
  totalLikes: number;
  roomTheme?: RoomTheme;
  type_specific: T;
  image_lede: string;
  publish_date: number;
  updated: number;
  inserted: number;
  wellness_migrated?: boolean;
};

export type IsoCountry = "AU" | "GB" | "NZ" | "US";
export type CurrencyType =
  | "AUD"
  | "GBP"
  | "NZD"
  | "USD"
  | "BIF"
  | "CLP"
  | "DJF"
  | "GNF"
  | "JPY"
  | "KMF"
  | "KRW"
  | "MGA"
  | "PYG"
  | "RWF"
  | "UGX"
  | "VND"
  | "VUV"
  | "XAF"
  | "XOF"
  | "XPF";

export type PlanLocale = { iso: string; currency: CurrencyType; price: number };

export type Plan = {
  _id: string;
  name: string;
  description: string;
  duration: number;
  duration_unit: "year" | "month";
  trial_duration: number | 0;
  trial_duration_unit: "day" | "month";
  price: number;
  locales: PlanLocale[];
  apple_product_id: string[];
  google_product_id: string[];

  auto_coupon?: {
    enabled: boolean;
    start_time: number;
    end_time: number;
    coupon: string;
    saleHeader?: string;
  };
};

export type Discount = {
  all_subscriptions: boolean;
  amount: number;
  permanent: boolean;
  subscriptions: string[];
  unit: "percentage" | "flat";
};
