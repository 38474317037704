import Link from "next/link";
import React, { useState } from "react";

import { userLoginLandingPage } from "../../../utils/constants";
import MenuItem from "./MenuItem";
import { Brand, Container, StyledIcon, Text, Wrapper } from "./styles";

const paymentMenu = [
  {
    name: "Web Plans and Discounts",
    icon: "subscription",
    route: "/admin/payments/web",
    as: "",
  },
  {
    name: "Mobile Plans",
    icon: "money-bill",
    route: "/admin/payments/mobile/plans",
    as: "",
  },
];

const legacyProgramMenu = {
  intro: {
    name: "KIC Start",
    icon: "star-half",
    route: "/admin/program/kic-start",
    as: "",
  },
  intermediate: {
    name: "KIC Fit",
    icon: "star-full",
    route: "/admin/program/kic-fit",
    as: "",
  },
};

const appContentMenu = {
  breakdowns: {
    name: "Breakdowns",
    icon: "clipboard-list",
    route: "/admin/post/breakdowns",
    as: "",
  },
  exerciseMedia: {
    name: "Exercise Media",
    icon: "dumbbell",
    route: "/admin/exercise-media",
    as: "",
  },
  ingredients: {
    name: "Ingredients",
    icon: "carrot",
    route: "/admin/post/ingredients",
    as: "",
  },
  masterclasses: {
    name: "Masterclasses",
    icon: "dumbbell",
    route: "/admin/post/masterclasses",
    as: "",
  },
  recipes: {
    name: "Recipes",
    icon: "utensils",
    route: "/admin/post/recipes",
    as: "",
  },
  runs: {
    name: "Runs",
    icon: "running",
    route: "/admin/post/runs",
    as: "",
  },
  guidedRuns: {
    name: "Guided Runs",
    icon: "running",
    route: "/admin/guided-runs",
    as: "",
  },
  runTypes: {
    name: "Run Types",
    icon: "running",
    route: "/admin/run-types",
    as: "",
  },
  series: {
    name: "Series",
    icon: "dumbbell",
    route: "/admin/series",
    as: "",
  },
  programs: {
    name: "KICXP Programs",
    icon: "clipboard-list",
    route: "/admin/programs",
    as: "",
  },
  challenges: {
    name: "Challenges",
    icon: "dumbbell",
    route: "/admin/challenge",
    as: "",
  },
  goals: {
    name: "Goals",
    icon: "target",
    route: "/admin/goal",
    as: "",
  },
};

const tagMenu = [
  {
    name: "Tags v2",
    icon: "user-tag",
    route: "/admin/tags/v2",
    as: "",
  },
  {
    name: "Ingredient Categories",
    icon: "fridge",
    route: "/admin/tags/ingredient-category",
    as: "",
  },
  {
    name: "Meal Goals",
    icon: "bullseye",
    route: "/admin/tags/meal-goals",
    as: "",
  },
  {
    name: "Meal Types",
    icon: "healthy-food",
    route: "/admin/tags/meal-types",
    as: "",
  },
  {
    name: "Roles",
    icon: "briefcase",
    route: "/admin/tags/roles",
    as: "",
  },
];

const SideBar = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Container open={isOpen}>
      <StyledIcon
        name="bars"
        onClick={() => setOpen(!isOpen)}
        open={isOpen}
        menubutton="true"
      />
      <Link href={userLoginLandingPage}>
        <Brand open={isOpen}>
          <StyledIcon name="kic-icon" />
          <div>
            <Text>KIC</Text>
            <Text>Admin</Text>
          </div>
        </Brand>
      </Link>
      <Wrapper open={isOpen}>
        <MenuItem name="Dashboard" icon="columns" route="/admin" />
      </Wrapper>
      <Wrapper open={isOpen}>
        <MenuItem name="Users" icon="users" route="/admin/users" />
      </Wrapper>
      <Wrapper open={isOpen}>
        <MenuItem name="Trainers" icon="whistle" route="/admin/trainers" />
      </Wrapper>
      <Wrapper open={isOpen}>
        <MenuItem
          name="Legacy Program"
          icon="stopwatch"
          route="/admin/program"
          disableLink
        >
          {Object.values(legacyProgramMenu).map((item) => (
            <MenuItem
              key={item.name}
              name={item.name}
              icon={item.icon}
              route={item.route}
              small="true"
            />
          ))}
        </MenuItem>
      </Wrapper>
      <Wrapper open={isOpen}>
        <MenuItem name="App Content" icon="book" disableLink>
          {Object.values(appContentMenu).map((item) => (
            <MenuItem
              key={item.name}
              name={item.name}
              icon={item.icon}
              route={item.route}
              small="true"
            />
          ))}
        </MenuItem>
      </Wrapper>
      <Wrapper open={isOpen}>
        <MenuItem name="Tags" icon="tags" route="/admin/tags" disableLink>
          {tagMenu.map((item) => (
            <MenuItem
              key={item.name}
              name={item.name}
              icon={item.icon}
              route={item.route}
              small="true"
            />
          ))}
        </MenuItem>
      </Wrapper>
      <Wrapper open={isOpen}>
        <MenuItem
          name="Media"
          icon="photo-video"
          route="/admin/media"
          asRoute="/admin/media"
        />
      </Wrapper>
      <Wrapper open={isOpen}>
        <MenuItem
          name="Videos"
          icon="photo-video"
          route="/admin/video"
          asRoute="/admin/video"
          disableLink
        >
          <MenuItem
            name="Assets"
            icon="photo-video"
            route="/admin/video/assets"
            asRoute="/admin/video/assets"
            small="true"
          />
          <MenuItem
            name="Upload"
            icon="photo-video"
            route="/admin/video/upload"
            asRoute="/admin/video/upload"
            small="true"
          />
        </MenuItem>
      </Wrapper>
      <Wrapper open={isOpen}>
        <MenuItem
          name="Payments"
          icon="shopping-bag"
          route="/admin/payments"
          disableLink
        >
          {paymentMenu.map((item) => (
            <MenuItem
              key={item.name}
              name={item.name}
              icon={item.icon}
              route={item.route}
              small="true"
            />
          ))}
        </MenuItem>
      </Wrapper>
      <Wrapper open={isOpen}>
        <MenuItem name="Globals" icon="globe" route="/admin/globals" />
      </Wrapper>
      <Wrapper open={isOpen}>
        <MenuItem name="Recommendations" icon="globe">
          <MenuItem
            name="What's New"
            icon="bullseye"
            route="/admin/recommendations/whats-new"
          />
          <MenuItem
            name="Explore Collections"
            icon="columns"
            route="/admin/recommendations/explore-collections"
          />
        </MenuItem>
      </Wrapper>
      <Wrapper open={isOpen}>
        <MenuItem name="Explore" icon="search">
          <MenuItem
            key="explore-all"
            name="Explore All"
            icon="search"
            route="/admin/explore-section"
            small="true"
          />
          <MenuItem
            key="explore-program-group"
            name="Program Group"
            icon="stopwatch"
            route="/admin/explore/explore-program-group"
            small="true"
          />
        </MenuItem>
      </Wrapper>
      <Wrapper open={isOpen}>
        <MenuItem
          name="Homepage Section"
          icon="home"
          route="/admin/homepage-section"
        />
      </Wrapper>
      <Wrapper open={isOpen}>
        <MenuItem
          name="Daily Quote"
          icon="subscription"
          route="/admin/daily-quote"
        />
      </Wrapper>
    </Container>
  );
};

export default React.memo(SideBar);
